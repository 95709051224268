<template>
  <div template-viewer>
    <component v-if="template" :is="template" />
    <FloatingAdminButton v-if="isLocal" />
  </div>
</template>
<script>
import { isLocal } from '@/utils/baseUtil';
import FloatingAdminButton from '@/views/components/common/FloatingAdminButton.vue';
import SumsubStart from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubStart.vue';
import SumsubPending from '@/views/components/pages/on-boarding/template/kyc/sumsub/SumsubPending.vue';
import SumsubReCheck from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubRecheck.vue';
import SumsubTryCountOver from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubTryCountOver.vue';
import SumsubTimeOver from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubServiceAbort.vue';
import SumsubCertificationFailed from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubCertificationFailed.vue';
import SumsubFailed from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubFailed.vue';
import SumsubDocumentInvalid from '@/views/components/pages/on-boarding/template/kyc/sumsub/failed/SumsubDocumentInvalid.vue';
import { sumsubStatus } from '@/constants/base/onboarding/kyc';

export default {
  name: 'TemplateViewer',
  components: { FloatingAdminButton},
  data() {
    return {
      failedViews: [
        { template: SumsubStart, status: sumsubStatus.None },
        { template: SumsubPending, status: sumsubStatus.Pending },
        { template: SumsubReCheck, status: sumsubStatus.ReCheck },
        { template: SumsubTryCountOver, status: sumsubStatus.TryCountOver },
        { template: SumsubTimeOver, status: sumsubStatus.ServiceAbort },
        { template: SumsubDocumentInvalid, status: sumsubStatus.DocumentInvalid },
        { template: SumsubCertificationFailed, status: sumsubStatus.CertificationFailed },
        { template: SumsubFailed, status: sumsubStatus.Failed },
      ],
    };
  },
  computed: {
    isLocal() {
      return isLocal();
    },
    template() {
      return this.failedViews.find(view => view.status === this.$route.query.status)?.template;
    },
  },
  methods: {
    initialize() {
      switch (this.$route.query.name) {
        default:
          break;
      }
    }
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[template-viewer] {
  [progress-button] { .mt(55); }
  > .floating { .fix(); .rt(20, 20);
    > button { .block(); .wh(60); .br(50%); .c(white); .semi-bold(); .bgc(#7815f5);
      @{hover-press} { .bgc(#785AF5); }
    }
    > button + button { .mt(15); }
  }
}
</style>